import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { ENV, getEnv } from 'src/providers/environment.provider';
import { AppComponent } from './app.component';
import {
  DialogComponent,
  ToastComponent,
  PickListComponent,
  LoadingComponent,
  SelectComponent,
  ButtonComponent,
  ProgressBarComponent
} from 'mis-component-library';
import { AppRoutingModule, DEV_LOGIN_ROUTE_CONFIG } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotFoundPageComponent } from './pages/not-found/not-found-page.component';
import { ApiIntecerceptorService } from './services/api-interceptor/api-intecerceptor.service';
import { DatePipe, CurrencyPipe, DecimalPipe } from '@angular/common';
import { CamelCasePipe } from './pipes/camel-case/camel-case.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginModule } from 'login';
import { environment } from 'src/environments/environment';
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';
import { UploadErrorDialogComponent } from './components/upload-error-dialog/upload-error-dialog.component';
import { MaterialModule } from './material/material.module';
import { ValuationCompleteDialogComponent } from './components/valuation-complete-dialog/valuation-complete-dialog.component';
import { NotAuthorisedPageComponent } from './pages/not-authorised/not-authorised-page.component';
import { UserDetailsComponent } from './pages/configuration/areas/users/user-details/user-details.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundPageComponent,
    LoadingDialogComponent,
    UploadErrorDialogComponent,
    ValuationCompleteDialogComponent,
    NotAuthorisedPageComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    ButtonComponent,
    BrowserModule,
    DialogComponent,
    FormsModule,
    SelectComponent,
    HttpClientModule,
    LoadingComponent,
    PickListComponent,
    ProgressBarComponent,
    ReactiveFormsModule,
    ToastComponent,
    MaterialModule,
    UserDetailsComponent,
    LoginModule.forRoot({
      licenseServerUrl: environment.licenseServerURL,
      // If the environment does not specifiy the login host, this register the login page components to be hosted itself
      pathConfig: !environment.loginHost ? DEV_LOGIN_ROUTE_CONFIG : undefined
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    })
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    CamelCasePipe,
    DecimalPipe,
    { provide: HTTP_INTERCEPTORS, useClass: ApiIntecerceptorService, multi: true },
    { provide: ENV, useFactory: getEnv }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AoT requires an exported function for factories
export function HttpLoaderFactory(handler: HttpBackend): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(handler, [
    { prefix: './assets/i18n/framework/', suffix: '.json' },
    { prefix: './assets/i18n/pages/not-found/', suffix: '.json' },
    { prefix: './assets/i18n/pages/assets/', suffix: '.json' },
    { prefix: './assets/i18n/pages/configuration/', suffix: '.json' },
    { prefix: './assets/i18n/pages/reports/', suffix: '.json' },
    { prefix: './assets/i18n/pages/previous-valuations/', suffix: '.json' },
    { prefix: './assets/i18n/pages/pricing/', suffix: '.json' },
    { prefix: './assets/i18n/pages/not-authorised/', suffix: '.json' },
  ])
}