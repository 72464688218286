import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BatchUploadStatusItem } from 'src/types/batch-upload-status';



@Component({
  selector: 'app-upload-error-dialog',
  templateUrl: './upload-error-dialog.component.html',
  styleUrl: './upload-error-dialog.component.scss'
})
export class UploadErrorDialogComponent implements OnInit {
  @Input() public statusItem: BatchUploadStatusItem | undefined;
  public errorTableData: MatTableDataSource<{ error: string, row: string }> = new MatTableDataSource<{ error: string, row: string }>([]);

  public columns: string[] = [
    'row',
    'error'
  ];

  public ngOnInit(): void {
    const errors: { error: string, row: string }[] = [];
    const lines = this.statusItem?.detail.split('\n') ?? [];
    for (const line of lines.filter(line => line.trim().length > 0)) {
      const [errorPart, rowPart] = line.split(/ \((\d+(?:,\d+)*)\)/);
      const rows = rowPart.split(',').map(Number).sort((a, b) => a - b);
      const error = errorPart.trim();
      if (error != '' && rows != undefined) {
        errors.push({ error, row: rows.join(", ") });
      }
    }
    this.errorTableData.data = errors;
  }

}
